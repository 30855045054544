<template>
  <v-row class="text-center align-center pt-10 white--text">
    <v-col class="col-md-8 col-12">
      <div class="video-list">
        <div class="thumbnail">
          <div class="thumbnail-img">
            <v-img
              :src="speaker.downloadImgUrl"
              class="grey darken-4"
              style="
                border-radius: 50%;
                width: 80px;
                height: 80px;
                min-width: 80px;
              "
            ></v-img>
          </div>
          <div class="thumbnail-info text-left">
            <h1>{{ speaker.first_name }} {{ speaker.last_name }}</h1>
            <p>
              {{ speaker.role }}
            </p>
          </div>
        </div>
      </div>
    </v-col>
    <v-col class="col-md-4 col-12 pr-7">
      <!-- // Show this card to not authenticated users -->
      <!-- color="bg_light" -->
      <!-- <v-card
        class="d-flex justify-space-between px-2 mb-2"
        v-if="!user"
        outlined
        dark
      >
        <div class="text-left">
          <v-card-title class="body-2">Richiedi lo screening</v-card-title>
          <v-card-subtitle class="caption">aA</v-card-subtitle>
        </div>
        <v-card-actions>
          <v-btn color="primary" to="/watch">AAA</v-btn>
        </v-card-actions>
      </v-card> -->
      <!-- // Show this card to logged in users  -->
      <v-card
        class="d-flex justify-space-between px-2"
        color="bg_light"
        v-if="user"
        outlined
      >
        <div class="text-left">
          <v-card-title class="body-2"
            >Chiedi a {{ speaker.first_name }}</v-card-title
          >
          <v-card-subtitle class="caption"
            >Fai una domanda o intavola un topic di discussione</v-card-subtitle
          >
        </div>
        <v-card-actions>
          <AskTheSpeaker :speaker="speaker" />
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SpeakerIntro",

  components: {
    AskTheSpeaker: () => import("@/components/webapp/AskTheSpeaker"),
  },

  props: {
    speaker: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>

<style scoped>
.thumbnail {
  display: flex;
}

.thumbnail img {
  width: 80px;
  height: 80px;
  min-width: 80px;
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  line-height: normal;
  position: relative;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
}

.thumbnail-info {
  margin-left: 20px;
}
</style>